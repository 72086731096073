@import "../../styles/helpers";

.top {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  width: calc(100% - 352px);
  padding-right: 128px;
  @include x {
    padding-right: 64px; }
  @include d {
    width: calc(100% - 304px);
    padding-right: 32px; }
  @include t {
    width: 100%;
    padding: 0; } }

.title {
  margin-right: auto; }

.button {
  @include m {
    width: 100%;
    margin-top: 16px; } }

.row {
  display: flex;
  align-items: flex-start;
  margin-top: -56px;
  @include t {
    flex-direction: column-reverse;
    margin-top: 0; } }

.wrapper {
  flex-grow: 1;
  padding-right: 128px;
  padding-top: 120px;
  @include x {
    padding-right: 64px; }
  @include d {
    padding-right: 32px; }
  @include t {
    width: 100%;
    padding: 0; } }

.plantedPlants {
  flex-shrink: 0;
  width: 352px;
  padding: 48px;
  border-radius: 24px;
  border: 1px solid $neutrals6;
  @include d {
    width: 304px;
    padding: 32px; }
  @include t {
    box-shadow: none;
    width: 100%;
    padding: 32px;
    margin-top: 24px; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -6px 32px;
  @include t {
    margin-top: 40px; }
  @include m {
    margin-top: 32px; } }

.link {
  margin: 0 6px;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  @include button-2;
  color: $neutrals4;
  transition: all .2s;

  &:hover {
    color: $neutrals3; }

  &.active {
    background: $neutrals3;
    color: $neutrals8; } }

.item {
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 16px 64px 16px 16px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include m {
    padding-right: 32px; }

  &:not(:last-child) {
    margin-bottom: 16px; } }

.indicator {
  width: 4px;
  height: 100%;
  border-radius: 7px;
  margin-right: 24px; }

.indicatorError {
  background-color: $pink; }

.indicatorWarn {
  background-color: $yellow; }

.indicatorSuccess {
  background-color: $green; }

.details {
  flex-grow: 1; }

.detailTitle {
  margin-bottom: 8px;
  @include body-bold-1;
  @include m {
    font-size: 16px; } }

.detailDescription {
  @include body-2;
  @include m {
    font-size: 12px; } }

.detailAdd {
  margin-top: 8px;
  margin-bottom: 16px; }

.detailPlants {
  margin-top: 26px; }

.modalTop {
  margin-bottom: 16px; }

.modalFooter {
  margin-top: 16px; }

.modalButton {
  width: 100%; }

.btns {
  display: flex;
  margin: 0 -6px;

  .button {
    width: calc(50% - 12px);
    flex: 0 0 calc(50% - 12px);
    height: 32px;
    margin: 0 6px;
    padding: 0 8px; } }

.itemPreviewList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 400px;
  overflow: auto; }

.itemPreviewItem {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px; }

.info {
  margin-bottom: 32px;
  @include body-bold-1; }

.group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $neutrals6; }

.checkbox {
  &:not(:last-child) {
    margin-bottom: 24px; } }
