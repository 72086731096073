@import "../../styles/helpers";

.top {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  padding-bottom: 32px;
  border-bottom: 1px solid $neutrals6;
  @include t {
    display: block;
    margin-bottom: 32px;
    padding-bottom: 0;
    border: none; }
  @include m {
    margin-bottom: 24px; } }

.search {
  position: relative;
  width: 100%; }

.input {
  width: 100%;
  height: 48px;
  padding: 0 48px 0 14px;
  border-radius: 12px;
  background: none;
  border: 2px solid $neutrals6;
  @include poppins;
  @include caption-1;
  color: $neutrals2;
  transition: border-color .2s;
  @include placeholder {
    color: $neutrals4; }

  &:focus {
    border-color: $neutrals4; } }

.result {
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $blue;
  transition: background .2s;

  svg {
    fill: $neutrals8; }

  &:hover {
    background: darken($blue, 10); } }

.row {
  display: flex;
  @include t {
    display: block; } }

.wrapper {
  flex-grow: 1;
  @include t {
    padding-left: 0; } }

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  @include m {
    display: block;
    margin: 0; } }

.listItem {
  flex: 33.333%;
  padding: 16px; }
