[class^="section"] {
  padding: 128px 0;
  @include x {
    padding: 112px 0; }
  @include m {
    padding: 64px 0; } }

.section-bg {
  background: $neutrals7; }

.section-pb {
  padding-top: 0;
  @include d {
    padding-top: 0; }
  @include m {
    padding-top: 0; } }

.section-pt80 {
  padding-top: 80px;
  @include d {
    padding-top: 64px; }
  @include m {
    padding-top: 32px; } }
