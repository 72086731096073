@import "../../styles/helpers";

.wrapper {
  position: relative; }

.inner {
  overflow: hidden; }

.preview {
  img {
    height: 200px;
    width: 100%;
    max-width: 250px;
    @include m {
      height: 350px;
      width: 100%;
      max-width: 100%; }
    object-fit: cover;
    border-radius: 8px; } }

.subtitle {
  margin-bottom: 8px;
  @include body-bold-1;
  color: $neutrals2; }

.line {
  display: flex;
  align-items: center; }

.counter {
  flex-shrink: 0; }
