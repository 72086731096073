@import "../../styles/helpers";

.item {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.itemPreviewList {
  padding-bottom: 16px;
  border-bottom: 1px solid $neutrals6;

  &:not(:last-child) {
    margin-bottom: 16px; } }

.thumbnail {
  position: relative;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 16px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover; } }

.details {
  flex-grow: 1; }

.description {
  width: 100%;
  margin-left: 64px;
  padding: 8px;
  margin-top: 12px;
  color: $neutrals3;
  border-radius: 14px;
  box-shadow: inset 0 0 0 1px $neutrals5; }

.title {
  color: $neutrals4; }

.value {
  font-weight: 500;
  text-decoration: none;
  color: $neutrals1; }
