@import "../../../styles/helpers";

.details {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 384px;
  @include d {
    width: 336px; }
  @include t {
    width: 100%; } }

.title {
  margin-bottom: 16px;

  span {
    color: $neutrals4; } }

.description {
  margin-bottom: 32px; }

.allowLineBreaks {
  white-space: pre-wrap; }

.attributes {
  margin-bottom: auto; }

.itemPreviewList {
  padding-bottom: 16px;
  border-bottom: 1px solid $neutrals6;

  &:not(:last-child) {
    margin-bottom: 16px; } }

.plantCollectionPreview {
  margin-bottom: 16px; }

.button {
  margin-top: 16px; }
