@import "../../styles/helpers";

.label {
  margin-bottom: 12px;
  @include hairline-2;
  color: $neutrals5; }

.textarea {
  width: 100%;
  height: 300px;
  padding: 10px 14px;
  resize: none;
  border-radius: 12px;
  border: 2px solid $neutrals6;
  background: none;
  @include poppins;
  @include caption-bold-1;
  color: $neutrals2;
  transition: border-color .2s;
  @include placeholder {
    color: $neutrals4; }

  &:focus {
    border-color: $neutrals4; } }
