@import "../../styles/helpers";

.top {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid $neutrals6;
  @include t {
    display: block;
    margin-bottom: 32px;
    padding-bottom: 0;
    border: none; }
  @include m {
    margin-bottom: 24px; } }

.actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include m {
    flex-wrap: wrap;
    button {
      width: 100%;
      margin-top: 16px; } } }

.row {
  display: flex;
  @include t {
    display: block; } }

.wrapper {
  flex-grow: 1;
  @include t {
    padding-left: 0; } }

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  @include m {
    display: block;
    margin: 0; } }

.listItemContainer {
  padding: 16px;
  width: 50%;
  @include m {
    width: 100%;
    padding-left: 0;
    padding-right: 0; }

  &:nth-child(odd) {
    padding-left: 0; }

  &:nth-child(even) {
    padding-right: 0; } }

.listItem {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid $neutrals5;
  border-radius: 14px;
  padding: 24px; }

.listItemIcon {
  background-color: $green;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 16px;
  padding: 6px; }

.listItemTitle {
  @include body-bold-2;
  font-size: 24px;
  color: $neutrals1; }

.modalTop {
  margin-bottom: 16px; }

.modalFooter {
  margin-top: 16px; }

.modalButton {
  width: 100%; }
