@import "../../styles/helpers";

.section {
  @include x {
    padding-top: 80px; }
  @include m {
    padding-top: 64px; } }

.container {
  display: flex;
  align-items: flex-start;
  @include t {
    display: block; } }

.wrapper {
  flex: 0 0 calc(100% - 352px);
  width: calc(100% - 352px);
  padding-right: 128px;
  @include x {
    padding-right: 64px; }
  @include d {
    flex: 0 0 calc(100% - 304px);
    width: calc(100% - 304px);
    padding-right: 32px; }
  @include t {
    width: 100%;
    padding: 0; } }

.head {
  display: flex;
  margin-bottom: 40px;
  @include d {
    flex-direction: column-reverse;
    align-items: flex-start; }
  @include m {
    margin-bottom: 32px; }

  .button {
    flex-shrink: 0;
    margin-left: 24px;
    @include d {
      margin: 0 0 16px; } } }

.title {
  margin-right: auto; }

.list {
  margin-bottom: 16px;
  padding-bottom: 16px;
  @include m {
    margin-bottom: 16px;
    padding-bottom: 16px; } }

.item {
  &:not(:last-child) {
    margin-bottom: 40px;
    @include m {
      margin-bottom: 32px; } } }

.note {
  margin-top: 4px;
  @include caption-2;
  color: $neutrals4; }

.file {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 182px;
  margin-top: 16px;
  border-radius: 16px;
  overflow: hidden;
  background: $neutrals7; }

.load {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 400px;
  opacity: 0; }

.icon {
  margin-bottom: 10px;

  svg {
    fill: $neutrals4; } }

.format {
  @include caption-2;
  color: $neutrals4; }

.category {
  @include body-bold-2; }

.fieldset {
  margin-top: 32px;

  .field {
    &:not(:last-child) {
      margin-bottom: 16px;
      @include m {
        margin-bottom: 20px; } } } }

.label {
  margin-bottom: 12px;
  @include hairline-2;
  color: $neutrals5; }

.row {
  display: flex;
  margin: 0 -10px;
  margin-bottom: 16px;
  @include m {
    display: block;
    margin: 0; } }

.col {
  flex: 0 0 calc(50% - 20px);
  width: calc(50% - 20px);
  margin: 0 10px;
  @include m {
    width: 100%;
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 20px; } } }

.options {
  margin-bottom: 40px;
  @include m {
    margin-bottom: 32px; } }

.option {
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 32px; } }

.box {
  flex-grow: 1; }

.text {
  margin-top: 4px;
  @include caption-2;
  color: $neutrals4; }

.foot {
  display: flex;
  align-items: center;
  @include m {
    display: block;
    text-align: center; }

  .button {
    @include m {
      width: 100%; }

    &:not(:last-child) {
      margin-right: 24px;
      @include m {
        margin: 0 0 12px; } } } }

.delete {
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  @include button-1; }
