@import "../../styles/helpers";

.attribute {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid $neutrals6;

  &:not(:last-child) {
    margin-bottom: 16px; } }

.thumbnail {
  position: relative;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 16px;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.details {
  flex-grow: 1; }

.title {
  color: $neutrals4; }

.value {
  font-weight: 500; }
