@import "../../styles/helpers";

.footer {
  border-top: 1px solid $neutrals6; }

.foot {
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  @include caption-2;
  @include m {
    display: block;
    padding: 24px 0 32px; } }

.info {
  color: $neutrals4; }
