@import "../../../styles/helpers";

.search {
  position: relative;
  margin-bottom: 24px;
  width: 100%;

  .input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 12px;
    background: none;
    border: 2px solid $neutrals6;
    @include poppins;
    @include caption-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include placeholder {
      color: $neutrals4; }

    &:focus {
      border-color: $neutrals4; } }

  .result {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: $blue;
    transition: background .2s;

    svg {
      fill: $neutrals8; }

    &:hover {
      background: darken($blue, 10); } } }

.itemPreviewList {
  padding-bottom: 16px;
  border-bottom: 1px solid $neutrals6;

  &:not(:last-child) {
    margin-bottom: 16px; } }
