@import "../../styles/helpers";

.errorWrapper {
  width: 100%;
  height: calc(100vh - 66px - 85px); }

.errorWrapperInner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 296px;
  margin-left: -148px;
  height: 160px;
  margin-top: -80px; }

.errorImage {
  margin-bottom: 24px; }

.errorDescription {
  color: $neutrals4;
  font-size: 125%; }
