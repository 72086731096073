@import "../../styles/helpers";

.section {
  padding: 96px 0;
  @include x {
    padding: 80px 0; }
  @include m {
    padding: 64px 0; } }

.container {
  display: flex;
  align-items: flex-start;
  @include t {
    display: block; } }

.bg {
  position: relative;
  flex-grow: 1;
  margin-right: 96px;
  @include x {
    margin-right: 64px; }
  @include d {
    margin-right: 32px; }
  @include t {
    margin: 0 0 32px; } }

.top {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @include m {
    display: block;
    margin-bottom: 32px; } }

.box {
  margin-right: auto;
  @include m {
    margin: 0 0 16px; }

  .dropdown {
    min-width: auto;
    margin-left: -24px;
    @include m {
      display: inline-block; } } }

.stage {
  @include body-bold-1;
  color: $neutrals4; }

.description {
  margin-top: 24px;
  margin-bottom: 16px; }

.area {
  margin-bottom: 32px; }

.areaDivider {
  border-bottom: 1px solid $neutrals5;
  margin: 32px 16px; }

.allowLineBreaks {
  white-space: pre-wrap; }

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  @include m {
    display: block;
    margin: 0; } }

.listItem {
  flex: 25%;
  padding: 16px; }

.buttonWrapper {
  display: flex;
  justify-content: right; }
