@import "../../styles/helpers";

.switch {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0; }

.input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  &:checked + .inner .box {
    background: $blue;

    &:before {
      transform: translate(24px, -50%);
      background: $neutrals8; } } }

.inner {
  position: relative;
  display: inline-block;
  transition: all .2s; }

.box {
  position: relative;
  display: block;
  width: 48px;
  height: 24px;
  background: $neutrals6;
  border-radius: 12px;
  transition: all .2s;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: $blue;
    transition: all .2s; } }
