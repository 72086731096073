@import "../../styles/helpers";

.section {
  padding: 96px 0;
  @include x {
    padding: 80px 0; }
  @include m {
    padding: 64px 0; } }

.container {
  display: flex;
  align-items: flex-start;
  @include t {
    display: block; } }

.bg {
  position: relative;
  flex-grow: 1;
  margin-right: 96px;
  @include x {
    margin-right: 64px; }
  @include d {
    margin-right: 32px; }
  @include t {
    margin: 0 0 32px; } }

.preview {
  position: relative;

  img {
    width: 100%;
    max-height: 550px;
    object-fit: cover;
    border-radius: 16px; } }

.categories {
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  flex-wrap: wrap;
  margin: -8px 0 0 -8px; }

.category {
  margin: 8px 0 0 8px; }

.options {
  position: absolute;
  top: auto;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%); }

.subtitle {
  margin-top: 16px;
  margin-bottom: 16px; }

.nav {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-around;
  padding: 6px;
  border-radius: 20px;
  box-shadow: inset 0 0 0 2px $neutrals6; }

.link {
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  @include button-2;
  color: $neutrals4;
  transition: all .2s;

  &:hover {
    color: $neutrals3; }

  &.active {
    background: $neutrals3;
    color: $neutrals8; }

  &:not(:last-child) {
    margin-right: 8px; } }

.neighbours {
  margin-bottom: auto; }

.control {
  margin-top: 40px;
  @include m {
    margin-top: 32px; } }

.propertiesNonMobile {
  display: block;
  @include t {
    display: none; } }

.propertiesMobile {
  margin-top: 24px;
  display: none;
  @include t {
    display: block; } }
